import { AnimatePresence } from 'motion/react';
import { FC, Fragment, useCallback } from 'react';

import { VpDialog } from '@vp/common/ui/component/VpDialog';
import { VpFade } from '@vp/common/ui/component/VpFade';
import { useSearchSection } from '@vp/common/ui/hook/useSearchSection';
import { SubmitRecordBody } from '@vp/honor/ui/components/SubmitRecordBody';
import { HonorManagerEditModal } from '@vp/manager/honor/ui/component/edit/HonorManagerEditModal';
import { HonorManagerList } from '@vp/manager/honor/ui/component/list/HonorManagerList';
import { HonorManagerSection } from '@vp/manager/honor/ui/HonorManagerSection';
import { RequestState, Status } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { useRecordEdit } from '@vp/manager/honor/ui/hook/useRecordEdit';
import { useRecordRemoval } from '@vp/manager/honor/ui/hook/useRecordRemoval';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

interface HonorManagerBodyProps {
  removeRecords: (ids: string[]) => Promise<Status>;
  approveRecords: (ids: string[]) => Promise<Status>;
  editRecord: (id: string, newText: string) => Promise<Status>;
  handleSubmissionResult: (status: ProfileHonorNotificationStatus, record: ProfileHonorRecordModel | null) => void;
  handleBeforeSubmission: () => void;
  profile: ProfileModel;
  pendingRecords: ProfileHonorRecordModel[];
  approvedRecords: ProfileHonorRecordModel[];
  activeRequestState: RequestState;
}

export const HonorManagerBody: FC<HonorManagerBodyProps> = ({
  removeRecords,
  editRecord,
  approveRecords,
  handleSubmissionResult,
  handleBeforeSubmission,
  profile,
  pendingRecords,
  approvedRecords,
  activeRequestState,
}) => {
  const activeSection = useSearchSection<HonorManagerSection>('s', HonorManagerSection.Approved);

  const handleRecordApprove = useCallback(
    async (record: ProfileHonorRecordModel) => void (await approveRecords([record.id])),
    [approveRecords],
  );
  const { openRemoveRecordDialog, closeRemoveRecordDialog, handleRemoveRecord, recordToRemove } = useRecordRemoval({
    removeRecords,
  });
  const { openRecordEditModal, closeRecordEditModal, handleRecordEdit, recordToEdit } = useRecordEdit({ editRecord });

  return (
    <Fragment>
      <AnimatePresence mode="wait">
        <VpFade key={activeSection} duration={0.25}>
          {activeSection === HonorManagerSection.Approved && (
            <HonorManagerList
              profile={profile}
              activeRequestState={activeRequestState}
              removeHandler={openRemoveRecordDialog}
              actionHandler={openRecordEditModal}
              actionButtonText="Править"
              records={approvedRecords}
            />
          )}
          {activeSection === HonorManagerSection.Pending && (
            <HonorManagerList
              profile={profile}
              activeRequestState={activeRequestState}
              removeHandler={openRemoveRecordDialog}
              actionHandler={handleRecordApprove}
              actionButtonText="Одобрить"
              records={pendingRecords}
            />
          )}
          {activeSection === HonorManagerSection.YourRecord && (
            <SubmitRecordBody
              profileId={profile.id}
              submitButtonText="Сохранить запись"
              handleBeforeSubmission={handleBeforeSubmission}
              handleSubmissionResult={handleSubmissionResult}
            />
          )}
        </VpFade>
      </AnimatePresence>

      <VpDialog
        maxWidth={450}
        onCancel={closeRemoveRecordDialog}
        onAction={handleRemoveRecord}
        actionLoading={activeRequestState.inProgress}
        cancelDisabled={activeRequestState.inProgress}
        open={!!recordToRemove}
        actionText="Удалить"
        cancelText="Отменить"
        title="Удалить почесть"
        text="Вы уверены, что хотите удалить предложенную запись? Это действие нельзя отменить."
      />

      <HonorManagerEditModal
        open={!!recordToEdit}
        close={closeRecordEditModal}
        handleEdit={handleRecordEdit}
        isRequestInProgress={activeRequestState.inProgress}
        record={recordToEdit}
      />
    </Fragment>
  );
};
