import { Box, Stack } from '@mui/material';
import { FC, Fragment } from 'react';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ProfileModel } from '@vp/profile/core/model/ProfileModel';
import { NoRecordsMessage } from '@vp/profile/ui/honor/NoRecordsMessage';
import { ProfileHonorRecords } from '@vp/profile/ui/honor/ProfileHonorRecords';
import { ProfileHonorViewModel } from '@vp/profile/ui/honor/ProfileHonorViewModel';
import { SubmitRecordModal } from '@vp/profile/ui/honor/SubmitRecordModal';
import { useSectionMinHeight } from '@vp/profile/ui/hook/useSectionMinHeight';

interface ProfileHonorProps {
  profile: ProfileModel;
}

export type ProfileHonorNotificationStatus = 'success' | 'error' | 'info';

export const ProfileHonor: FC<ProfileHonorProps> = ({ profile }) => {
  const viewModel = useViewModel(ProfileHonorViewModel);
  const minHeight = useSectionMinHeight();

  return (
    <Fragment>
      <Stack minHeight={minHeight} px={{ mobile: 3, tablet: 7 }} pt={{ mobile: 2, tablet: 3 }} alignItems="center">
        {profile.honorRecords.length > 0 ? <ProfileHonorRecords records={profile.honorRecords} /> : <NoRecordsMessage />}
        <Box
          sx={{
            width: 1,
            position: 'sticky',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'background.300',
            py: { mobile: 2, tablet: 4 },
          }}
        >
          <VpFancyButton text="Оставить запись" onClick={viewModel.openSubmitRecordModal}></VpFancyButton>
        </Box>
      </Stack>

      <SubmitRecordModal
        profileId={profile.id}
        open={viewModel.showSubmitRecordModal.value}
        handleSubmissionResult={viewModel.handleSubmissionResult}
        close={viewModel.closeSubmitRecordModal}
      />
    </Fragment>
  );
};
