import { Box } from '@mui/material';
import { FC } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpLoadingWrapper } from '@vp/common/ui/component/VpLoadingWrapper';
import { useSearchSectionCheck } from '@vp/common/ui/hook/useSearchSectionCheck';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { HonorManagerHeader } from '@vp/manager/honor/ui/component/header/HonorManagerHeader';
import { HonorManagerBody } from '@vp/manager/honor/ui/component/HonorManagerBody';
import { HonorManagerSection } from '@vp/manager/honor/ui/HonorManagerSection';
import { HonorManagerViewModel } from '@vp/manager/honor/ui/HonorManagerViewModel';

export const HonorManagerPage: FC = () => {
  const viewModel = useViewModel(HonorManagerViewModel);

  useSearchSectionCheck('s', HonorManagerSection.Approved);

  return (
    <VpFade sx={{ width: 1, height: '100dvh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <HonorManagerHeader
        showSelectButton={viewModel.isActiveTabHasRecords.value && viewModel.status.value === 'loaded'}
        disableInteractions={viewModel.activeRequestState.value.inProgress}
      />

      <Box sx={{ flexGrow: 1, overflowY: 'auto', px: { mobile: 3, tablet: 7 } }}>
        <VpLoadingWrapper loading={viewModel.status.value !== 'loaded'}>
          <HonorManagerBody
            removeRecords={viewModel.removeRecords}
            approveRecords={viewModel.approveRecords}
            editRecord={viewModel.editRecord}
            handleBeforeSubmission={viewModel.handleBeforeSubmission}
            handleSubmissionResult={viewModel.handleSubmissionResult}
            profile={viewModel.profile.value!}
            pendingRecords={viewModel.pendingRecords.value}
            approvedRecords={viewModel.approvedRecords.value}
            activeRequestState={viewModel.activeRequestState.value}
          />
        </VpLoadingWrapper>
      </Box>
    </VpFade>
  );
};
