import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { VpTextField } from '@vp/common/ui/component/form/VpTextField';
import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { BiographyManagerEditHeader } from '@vp/manager/biography/ui/edit/BiographyManagerEditHeader';

export interface EditModalFormValues {
  suggestedText: string;
}

interface BiographyManagerEditContentProps {
  close: () => void;
  submit: (values: EditModalFormValues) => void;
  text: string;
}

export const BiographyManagerEditContent: FC<BiographyManagerEditContentProps> = ({ close, submit, text }) => {
  const methods = useForm<EditModalFormValues>({
    defaultValues: { suggestedText: text },
    mode: 'onTouched',
  });

  return (
    <Stack width={1} alignItems="center" height="100dvh" component="form" onSubmit={methods.handleSubmit(submit)}>
      <BiographyManagerEditHeader onClose={close} />

      <Stack flexGrow={1} width={1} overflow="auto" pt={1} alignItems="center">
        <VpTextField
          sx={{ width: 1, maxWidth: 650, px: { mobile: 3, tablet: 7, desktop: 0 } }}
          fullWidth
          multiline
          id="form-suggested-text"
          label="Описание"
          error={!!methods.formState.errors.suggestedText}
          slotProps={{
            multilineInput: {
              registerReturn: methods.register('suggestedText', { required: true }),
              placeholder:
                'Напишите биографию человека, включая ключевые моменты его жизни, достижения и наследие, которое он оставил для будущих поколений.',
            },
          }}
        />

        <Stack p={1} px={{ mobile: 3, tablet: 7 }} mt={1}>
          <Typography variant="caption2Regular" color="text.disabled" align="center" sx={{ whiteSpace: 'pre-line' }}>
            Сгенерировано при помощи Искусственного Интеллекта
          </Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          width: 1,
          pt: 2,
          pb: { mobile: 2, tablet: 4 },
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'background.300',
        }}
      >
        <VpFancyButton text="Применить" type="submit" />
      </Box>
    </Stack>
  );
};
