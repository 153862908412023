import { injectable } from 'inversify';

import { Action } from '@vp/manager/honor/ui/HonorManagerViewModel';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

interface NotificationMessage {
  primary: string;
  secondary: string;
}

interface NotificationStatusMessages {
  success: NotificationMessage;
  error: NotificationMessage;
  info: NotificationMessage;
}

const EditNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Почесть была отредактирована',
    secondary: 'Вы можете изменить её в любой момент',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const ApproveNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Почесть была одобрена',
    secondary: 'Вы можете найти её во вкладке Одобренные',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const RemoveNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Почесть была удалена',
    secondary: 'Это действие нельзя отменить',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const SubmitNotificationText: NotificationStatusMessages = {
  success: {
    primary: 'Ваша запись была отправлена',
    secondary: 'Вы можете найти её во вкладке Одобренные',
  },
  error: {
    primary: 'Что-то пошло не так.',
    secondary: 'Обновите страницу и попробуйте ещё раз.',
  },
  info: {
    primary: '',
    secondary: '',
  },
};

const NotificationText: Record<Action, NotificationStatusMessages> = {
  edit: EditNotificationText,
  approve: ApproveNotificationText,
  remove: RemoveNotificationText,
  submit: SubmitNotificationText,
};

@injectable()
export class HonorManagerNotificationService {
  constructor(private readonly notificationService: AppNotificationService) {}

  showNotification(status: ProfileHonorNotificationStatus, action: Action): void {
    const { primary, secondary } = NotificationText[action][status];

    this.notificationService.enqueue({
      variant: status,
      message: primary,
      secondaryMessage: secondary,
    });
  }
}
