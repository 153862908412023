import { Box, Fade, Modal, useTheme } from '@mui/material';
import { ModalOwnProps } from '@mui/material/Modal/Modal';
import { FC, useCallback, useState } from 'react';

import { HonorManagerEditContent } from '@vp/manager/honor/ui/component/edit/HonorManagerEditContent';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';

export interface HonorManagerEditModalProps {
  close: () => void;
  handleEdit: (id: string, newText: string) => Promise<void>;
  open: boolean;
  record: ProfileHonorRecordModel | null;
  isRequestInProgress: boolean;
}

export const HonorManagerEditModal: FC<HonorManagerEditModalProps> = ({ open, close, handleEdit, record, isRequestInProgress }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;
  const [displayedRecord, setDisplayedRecord] = useState<ProfileHonorRecordModel | null>(null);

  const handleEntering = useCallback(() => {
    if (record) {
      setDisplayedRecord(record);
    }
  }, [record]);

  const handleExited = useCallback(() => {
    setDisplayedRecord(null);
  }, []);

  const onClose = useCallback<NonNullable<ModalOwnProps['onClose']>>((_, reason) => reason === 'escapeKeyDown' && close?.(), [close]);

  return (
    <Modal open={open} aria-modal hideBackdrop onClose={onClose}>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out" onEntering={handleEntering} onExited={handleExited}>
        <Box
          width={{ mobile: 1, desktop: '53%' }}
          height={1}
          autoFocus
          tabIndex={0}
          position="relative"
          sx={({ palette }) => ({ overflowY: 'auto', outline: 'none', backgroundColor: palette.background.default })}
        >
          {displayedRecord && (
            <HonorManagerEditContent
              close={close}
              handleEdit={handleEdit}
              record={displayedRecord}
              isRequestInProgress={isRequestInProgress}
            />
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
