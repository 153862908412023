import { Signal, signal } from '@preact/signals-react';
import { injectable } from 'inversify';

import { ViewModel } from '@vp/common/ui/ViewModel';
import { AppNotificationService } from '@vp/notification/AppNotificationService';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

@injectable()
export class ProfileHonorViewModel extends ViewModel {
  readonly showSubmitRecordModal: Signal<boolean> = signal(false);

  constructor(private readonly notificationService: AppNotificationService) {
    super();
  }

  handleSubmissionResult = (status: ProfileHonorNotificationStatus): void => {
    if (status === 'success') {
      this.showSuccessNotification();
      this.closeSubmitRecordModal();
    } else {
      this.showErrorNotification();
    }
  };

  openSubmitRecordModal = (): void => {
    this.showSubmitRecordModal.value = true;
  };

  closeSubmitRecordModal = (): void => {
    this.showSubmitRecordModal.value = false;
  };

  private showSuccessNotification(): void {
    this.notificationService.enqueue({
      variant: 'success',
      message: 'Ваша запись была отправлена',
      secondaryMessage: 'Если администратор одобрит запись, она появятся на вкладке “Почести”',
    });
  }

  private showErrorNotification(): void {
    this.notificationService.enqueue({
      variant: 'error',
      message: 'Что-то пошло не так',
      secondaryMessage: 'Обновите страницу и попробуйте ещё раз.',
    });
  }
}
