import { useState, useCallback } from 'react';

import { Status } from '@vp/manager/honor/ui/HonorManagerViewModel';

interface UseRecordRemovalProps {
  removeRecords: (ids: string[]) => Promise<Status>;
}

export interface RecordRemovalState {
  openRemoveRecordDialog: (id: string) => void;
  closeRemoveRecordDialog: () => void;
  handleRemoveRecord: () => Promise<void>;
  recordToRemove: string | null;
}

export const useRecordRemoval = ({ removeRecords }: UseRecordRemovalProps): RecordRemovalState => {
  const [recordToRemove, setRecordToRemove] = useState<string | null>(null);

  const openRemoveRecordDialog = useCallback((id: string) => setRecordToRemove(id), []);
  const closeRemoveRecordDialog = useCallback(() => setRecordToRemove(null), []);

  const handleRemoveRecord = useCallback(async () => {
    if (recordToRemove) {
      await removeRecords([recordToRemove]);
      setRecordToRemove(null);
      closeRemoveRecordDialog();
    }
  }, [recordToRemove, removeRecords, closeRemoveRecordDialog]);

  return {
    openRemoveRecordDialog,
    closeRemoveRecordDialog,
    handleRemoveRecord,
    recordToRemove,
  };
};
