import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence } from 'motion/react';
import { FC, Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { VpFallback } from '@vp/common/ui/component/VpFallback';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { ManagerGatewayViewModel } from '@vp/manager/gateway/ManagerGatewayViewModel';
import { ProfileDesktopImage } from '@vp/profile/ui/ProfileDesktopImage';

export const ManagerGateway: FC = () => {
  const viewModel = useViewModel(ManagerGatewayViewModel);

  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('desktop'));

  if (!viewModel.initialized.value) return null;

  return (
    <Fragment>
      {viewModel.hasError.value && <VpFallback title="Что-то пошло не так" />}

      {!viewModel.hasError.value && (
        <Stack sx={{ width: 1, flexDirection: 'row' }}>
          <Box sx={{ width: 1, [breakpoints.up('desktop')]: { width: '53%', overflowY: 'auto' } }}>
            <Outlet />
          </Box>

          {isDesktop && viewModel.profile.value && (
            <Box sx={{ position: 'relative', width: '47%' }}>
              <AnimatePresence mode="wait">
                <VpFade duration={0.25} key={viewModel.profile.value.image.id}>
                  <ProfileDesktopImage image={viewModel.profile.value.image} />
                </VpFade>
              </AnimatePresence>
            </Box>
          )}
        </Stack>
      )}
    </Fragment>
  );
};
