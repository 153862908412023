import { Box, Fade, Modal, useTheme } from '@mui/material';
import { FC } from 'react';

import { ProfilePhotoModel } from '@vp/profile/core/model/ProfilePhotoModel';
import { SlideshowGalleryContent } from '@vp/slideshow/ui/component/gallery/SlideshowGalleryContent';

export interface SlideshowGalleryModalProps {
  open: boolean;
  close: (goToImageId?: string) => void;
  images: ProfilePhotoModel[];
  profileName: string;
}

export const SlideshowGalleryModal: FC<SlideshowGalleryModalProps> = ({ open, close, images, profileName }) => {
  const { transitions } = useTheme();
  const fadeDuration = transitions.duration.modalFadeDuration;

  return (
    <Modal open={open} aria-modal hideBackdrop>
      <Fade in={open} timeout={fadeDuration} easing="ease-in-out">
        <Box
          width={1}
          height={1}
          autoFocus
          tabIndex={0}
          position="relative"
          sx={({ palette }) => ({
            display: 'flex',
            justifyContent: 'center',
            overflowY: 'auto',
            outline: 'none',
            backgroundColor: palette.background.default,
          })}
        >
          <SlideshowGalleryContent profileName={profileName} close={close} images={images} />
        </Box>
      </Fade>
    </Modal>
  );
};
