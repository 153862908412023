import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { SubmitRecordBody } from '@vp/honor/ui/components/SubmitRecordBody';
import { SubmitRecordHeader } from '@vp/honor/ui/components/SubmitRecordHeader';
import { HonorSubmitViewModel } from '@vp/honor/ui/HonorSubmitViewModel';
import { ProfileHonorRecordModel } from '@vp/profile/core/model/ProfileHonorRecordModel';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';
import { scrollbarStyles } from '@vp/theme/scrollbarStyles';

interface SubmitRecordContentProps {
  submitButtonText: string;
  profileId: string;
  close: () => void;
  handleSubmissionResult: (status: ProfileHonorNotificationStatus, record: ProfileHonorRecordModel | null) => void;
  handleBeforeSubmission?: () => void;
}

export const SubmitRecordContent: FC<SubmitRecordContentProps> = ({
  handleSubmissionResult,
  handleBeforeSubmission,
  close,
  submitButtonText,
  profileId,
}) => {
  const viewModel = useViewModel(HonorSubmitViewModel);

  return (
    <Stack height={'100dvh'}>
      <SubmitRecordHeader close={close} disableCloseIcon={viewModel.submitting.value} />

      <Box
        sx={theme => ({
          overflow: 'auto',
          px: { mobile: 3, tablet: 7 },
          [theme.breakpoints.up('desktop')]: {
            ...scrollbarStyles(theme),
          },
        })}
      >
        <SubmitRecordBody
          sx={{ maxWidth: 650 }}
          profileId={profileId}
          handleBeforeSubmission={handleBeforeSubmission}
          handleSubmissionResult={handleSubmissionResult}
          submitButtonText={submitButtonText}
        />
      </Box>
    </Stack>
  );
};
