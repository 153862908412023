import { Box, Stack } from '@mui/material';
import { FC, Fragment } from 'react';

import { VpFade } from '@vp/common/ui/component/VpFade';
import { useSearchSectionCheck } from '@vp/common/ui/hook/useSearchSectionCheck';
import { useViewModel } from '@vp/common/ui/hook/useViewModel';
import { GalleryManagerControls } from '@vp/manager/gallery/ui/GalleryManagerControls';
import { GalleryManagerMediaGrid } from '@vp/manager/gallery/ui/GalleryManagerMediaGrid';
import { GalleryManagerMediaTabs } from '@vp/manager/gallery/ui/GalleryManagerMediaTabs';
import { GalleryManagerPageHeader } from '@vp/manager/gallery/ui/GalleryManagerPageHeader';
import { GalleryManagerSection } from '@vp/manager/gallery/ui/GalleryManagerSection';
import { GalleryManagerViewModel } from '@vp/manager/gallery/ui/GalleryManagerViewModel';
import { GalleryManagerPreviewModal } from '@vp/manager/gallery/ui/preview/GalleryManagerPreviewModal';
import { GalleryManagerUploadModal } from '@vp/manager/gallery/ui/upload/GalleryManagerUploadModal';

export const GalleryManagerPage: FC = () => {
  const viewModel = useViewModel(GalleryManagerViewModel);
  useSearchSectionCheck('s', GalleryManagerSection.Photos);

  return (
    <Fragment>
      <VpFade>
        <Stack width={1} height="100dvh">
          <GalleryManagerPageHeader />
          <GalleryManagerMediaTabs />

          <Box flexGrow={1} overflow="auto">
            <GalleryManagerMediaGrid photos={viewModel.photos.value} videos={viewModel.videos.value} onClick={viewModel.showPreview} />
          </Box>

          <GalleryManagerControls onFileUpload={viewModel.handleFiles} />
        </Stack>
      </VpFade>

      <GalleryManagerUploadModal files={viewModel.filesToUpload.value} open={viewModel.uploadShown.value} close={viewModel.closeUpload} />
      <GalleryManagerPreviewModal item={viewModel.previewItem.value} open={viewModel.previewShown.value} close={viewModel.closePreview} />
    </Fragment>
  );
};
